@font-face {
    font-family: 'THSarabunNew';
    src: url('../font/THSarabunNew.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

.font-th {
    font-family: 'THSarabunNew';
}

.nav-font-size{
    font-size: 25px;
}

.copy-right-size{
    font-size: 20px;
}