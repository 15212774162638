html,
body {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  width: 100%;
  height: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

* {
  box-sizing: border-box;
}

::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgb(101 104 79);
  box-shadow: 0 0 1px hsl(0deg 0% 100% / 50%);
}

/* structure */
#root {
  background-image: url("../images/bg/bg-main2.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: relative;
  padding: 65px;
  box-sizing: border-box;
  overflow-y: scroll;
  color: white;
}

#main{
  padding: 0;
}

.root-elem {
  background-image: url("../images/bg/top-left.png"), url("../images/bg/top-right.png"),
    url("../images/bg/buttom-left.png"), url("../images/bg/buttom-right.png");
  background-size: 150px 150px, 150px 150px, 150px 150px, 150px 150px;
  background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
  background-position: top left, top right, bottom left, bottom right;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
}

.container {
  max-width: 1280px;
  height: 100%;
  margin: auto;
  position: relative;
  z-index: 1;
}

.container-wrp {
  width: 100%;
  height: 100%;
}

/* banner */
.banner {
  width: 100%;
}

.banner-image {
  object-fit: cover;
  width: 100%;
  height: 100%;
  max-height: 500px;
}

/* nav bar */
.nav-bar {
  width: 100%;
  height: 75px;
  position: relative;
  padding-left: 50px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-bar-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  background-color: white;
  overflow: hidden;
}

.nav-bar-image-logo {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.nav-bar-wrp {
  width: 100%;
  height: 60px;
  background: linear-gradient(
    90deg,
    rgba(0, 17, 255, 1) 0%,
    rgba(0, 14, 255, 1) 30%,
    rgba(0, 17, 255, 0) 100%
  );
  display: flex;
  padding-left: 25px;
}

.nav-bar-item {
  width: 100px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-bar-item-image {
  width: 30px;
  height: 30px;
}

.nav-bar-item-label {
  color: white;
  text-decoration: unset;
}

.nav-bar-item-label:hover {
  text-decoration: underline;
}

/* content */
.content {
  background-image: url("../images/bg/bg-body.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;
  min-height: 835px;
  padding: 20px;
}

.content-logo {
  width: 280px;
  height: 280px;
  border-radius: 50%;
  overflow: hidden;
  margin: auto;
}

.content-image-logo {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.content-title {
  font-size: 40px;
  text-align: center;
}

.content-desc {
  font-size: 16px;
  text-align: center;
}

/* About me */
.about-me {
  width: 100%;
  min-height: 200px;
  background-color: #010323;
  padding: 20px;
}

.about-me-title {
  font-size: 35px;
}

.about-me-desc {
  font-size: 22px;
}

.copy-right {
  height: 75px;
  display: flex;
  justify-content: center;
}

/* Utils */
.mt-4 {
  margin-top: 40px;
}

.mb-2 {
  margin-bottom: 20px;
}

.w-100 {
  width: 100%;
}

@media screen and (max-width: 768px) {
  #root {
    padding: 30px;
  }
  .root-elem {
    background-size: 75px 75px, 75px 75px, 75px 75px, 75px 75px;
  }
}

@media (min-width:300px) {
  .content-title {
      font-size: 30px;
  }
  .nav-font-size{
    font-size: 20px;
  }
  .content-desc {
    font-size: 18px;
  }
  .copy-right-size{
    font-size: 16px;
  }
  .about-me-desc {
    font-size: 18px;
  }
  .about-me-title {
    font-size: 27px;
  }
}

@media (min-width:768px) {
  .content-title {
      font-size: 30px;
  }
  .nav-font-size{
    font-size: 25px;
  }

  .content-desc {
    font-size: 20px;
  }
  .copy-right-size{
    font-size: 18px;
  }
  .about-me-desc {
    font-size: 20px;
  }
  .about-me-title {
    font-size: 30px;
  }
}
@media (min-width:1024px) {
  .content-title {
      font-size: 35px;
  }
  .nav-font-size{
    font-size: 25px;
  }

  .content-desc {
    font-size: 22px;
  }
  .copy-right-size{
    font-size: 20px;
  }
  .about-me-desc {
    font-size: 22px;
  }
  .about-me-title {
    font-size: 35px;
  }
}
@media (min-width:1280px) {
  .content-title {
      font-size: 40px;
  }
  .nav-font-size{
    font-size: 25px;
  }

  .content-desc {
    font-size: 25px;
  }
  .copy-right-size{
    font-size: 20px;
  }
  .about-me-desc {
    font-size: 22px;
  }
  .about-me-title {
    font-size: 35px;
  }
}


.product-desc{
  max-width: 70%;
  margin: auto;
  text-align: unset;
}